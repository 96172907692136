import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as CONSTANTS from "./constants";

function Profile(props) {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    async function getToken() {
      try {
        if (props.obtained) return;

        let token = await getAccessTokenSilently({
          audience: `${CONSTANTS.API_PATH}`,
          // scope: "read:current_user",
        });
        props.setToken(token);
      } catch (e) {
        console.log(e);
      }
    }
    getToken();
  });

  return null;
}

export default Profile;
