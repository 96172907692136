import React from "react";
import * as CONSTANTS from "./../constants";

class NewGroupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupName: "New group",
      authorizationHeader: props.authorizationHeader,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleSubmit(event) {
    fetch(`${CONSTANTS.API_PATH}/group/`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.authorizationHeader.Authorization,
      },
      body: JSON.stringify({ name: this.state.groupName }),
    })
      .then((response) => response.status)
      .then((data) => {
        console.log("Success:", data);
        this.props.onRefresh();
      });
    event.preventDefault();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <input
          name="groupName"
          type="text"
          id="group-name"
          value={this.state.groupName}
          onChange={this.handleInputChange}
        />
        <button>Add Group</button>
      </form>
    );
  }
}

export default NewGroupForm;
