import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import MainPage from "./MainPage";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";

function App(props) {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return (
      <>
        <LogoutButton></LogoutButton>
        <MainPage />
      </>
    );
  } else
    return (
      <>
        <LoginButton></LoginButton>
      </>
    );
}

ReactDOM.render(
  <Auth0Provider
    domain="adraganov.eu.auth0.com"
    clientId="S6Vl49bgP8ccMz5kjsWoGUvo7u5wXSq2"
    redirectUri={window.location.origin}
    audience="https://habitsapi.adraganov.com/"
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);
