import React from "react";
import * as CONSTANTS from "./../constants";
import * as utils from "./../utils";

class IncrementForm extends React.Component {
  constructor(props) {
    super(props);
    let date = props.increment == null ? new Date() : new Date(props.increment.date);
    this.state = {
      date: utils.formatDate(date),
      incrementValue: props.increment == null ? 0 : props.increment.value,
      increment: props.increment,
      incrementExists: props.increment != null,
      authorizationHeader: props.authorizationHeader,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleSubmit(event) {
    if (this.state.incrementValue > 0) {
      fetch(`${CONSTANTS.API_PATH}/increment/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.state.authorizationHeader.Authorization,
        },
        body: JSON.stringify({
          counter_id: this.props.counterID,
          value: this.state.incrementValue,
          date: this.state.date,
          note: "From App",
        }),
      })
        .then((response) => response.status)
        .then((data) => {
          console.log("Success:", data);
          this.props.onRefresh();
          this.setState({ incrementExists: true });
          this.loadIncrement(this.state.date);
        });
    }
    event.preventDefault();
  }

  handleDelete(event) {
    if (!this.state.increment) return;

    fetch(`${CONSTANTS.API_PATH}/increment/${this.state.increment._id}`, {
      method: "DELETE",
      headers: this.state.authorizationHeader,
    })
      .then((response) => response.status)
      .then((data) => {
        console.log("Success:", data);
        this.setState({
          incrementExists: false,
          increment: null,
          incrementValue: 0,
          date: utils.formatDate(new Date()),
        });
        this.props.onRefresh();
      });

    event.preventDefault();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleDateChange(event) {
    this.handleInputChange(event);
    this.loadIncrement(event.target.value);
  }

  loadIncrement(date) {
    let filter = JSON.stringify({ date: date, counterid: this.props.counterID });
    fetch(`${CONSTANTS.API_PATH}/increment/${filter}`, { headers: this.state.authorizationHeader })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.length === 1)
          this.setState({
            incrementValue: data[0].value,
            incrementExists: true,
            increment: data[0],
          });
        else this.setState({ incrementValue: 0, incrementExists: false, increment: null });
      });
  }

  render() {
    return (
      <form>
        <div className="input-group">
          <input
            className="form-control"
            type="date"
            name="date"
            value={this.state.date}
            onChange={this.handleDateChange}
          ></input>
          <input
            className={
              "form-control increment-input " +
              (this.state.incrementExists ? "existing-increment" : "")
            }
            type="number"
            name="incrementValue"
            id="increment-spinner"
            value={this.state.incrementValue}
            onChange={this.handleInputChange}
          />
          <button className="btn btn-outline-secondary" onClick={this.handleSubmit}>
            UP
          </button>
          <button className="btn btn-outline-secondary" onClick={this.handleDelete}>
            DL
          </button>
        </div>
      </form>
    );
  }
}

export default IncrementForm;
