import "./App.css";
import React from "react";
import NewCounterForm from "./forms/NewCounterForm";
import NewGroupForm from "./forms/NewGroupForm";
import IncrementForm from "./forms/IncrementForm";
import Profile from "./Profile";
import * as CONSTANTS from "./constants";
import * as utils from "./utils";

function Counter(props) {
  let counter = props.counter;
  let takeAction =
    counter.days_in_row > 0 &&
    new Date(counter.last_update).getTime() ===
      utils.addDaysToDate(new Date(new Date().toDateString()), -1).getTime();

  return (
    <div key={counter._id} className="row">
      <div className="input-group">
        <div className="col">
          <label className="input-group-text">{counter.groupName}</label>
        </div>
        <div className="col">
          <label className="input-group-text">{counter.name}</label>
        </div>
        <div className="col">
          <label className="input-group-text">{counter.value}</label>
        </div>
        <div className="col">
          <label className="input-group-text">{counter.days_in_row}</label>
        </div>
        <div className="col">
          <label className={"input-group-text " + (takeAction ? "take-action" : "")}>
            {utils.formatDate(new Date(counter.last_update))}
          </label>
        </div>

        <div className="col-4">
          <IncrementForm
            counterID={counter._id}
            onRefresh={props.onRefresh}
            increment={props.increment}
            authorizationHeader={props.authorizationHeader}
          />
        </div>
      </div>
      {"\n"}
    </div>
  );
}

function CounterList(props) {
  return (
    <div id="counters-list">
      <div className="row">
        <div className="col">Group name</div>
        <div className="col">Counter name</div>
        <div className="col">Value</div>
        <div className="col">Days in row</div>
        <div className="col">Last Update</div>
        <div className="col-4">Increment</div>
      </div>
      {props.counters.map((value, index) => {
        var counter = {};
        counter = Object.assign(counter, value);
        var groupName = "-";
        if (value.group_id) {
          var idx = props.groups.findIndex((el) => el._id === value.group_id);
          if (idx > -1) groupName = props.groups[idx].name;
        }

        let incrIndex = props.todayIncrements.findIndex((el) => el.counter_id === counter._id);
        let todayIncrement = incrIndex > -1 ? props.todayIncrements[incrIndex] : null;

        counter.groupName = groupName;
        return Counter({
          counter: counter,
          onRefresh: props.onRefresh,
          increment: todayIncrement,
          authorizationHeader: props.authorizationHeader,
        });
      })}
    </div>
  );
}

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counters: [],
      groups: [],
      todayIncrements: [],
      authorizationHeader: null,
      dataLoaded: false,
    };

    this.refreshDataFromServer = this.refreshDataFromServer.bind(this);
    this.setToken = this.setToken.bind(this);
  }

  refreshDataFromServer() {
    //load the counters
    fetch(`${CONSTANTS.API_PATH}/counter/`, { headers: this.state.authorizationHeader })
      .then((response) => response.json())
      .then((data) => this.setState({ counters: data }));

    //load the groups
    fetch(`${CONSTANTS.API_PATH}/group/`, { headers: this.state.authorizationHeader })
      .then((response) => response.json())
      .then((data) => this.setState({ groups: data }));
  }

  loadTodayIncrements() {
    fetch(
      `${CONSTANTS.API_PATH}/increment/${JSON.stringify({ date: utils.formatDate(new Date()) })}`,
      { headers: this.state.authorizationHeader }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ todayIncrements: data, dataLoaded: true });
        //console.log(data);
      });
  }

  setToken(token) {
    this.setState({ authorizationHeader: { Authorization: `Bearer ${token}` } });
    this.loadTodayIncrements();
    this.refreshDataFromServer();
  }

  render() {
    if (this.state.authorizationHeader && this.state.dataLoaded) {
      return (
        <div className="App">
          <Profile
            setToken={this.setToken}
            obtained={this.state.authorizationHeader ? true : false}
          />
          <div className="container-fluid">
            <CounterList
              counters={this.state.counters}
              groups={this.state.groups}
              todayIncrements={this.state.todayIncrements}
              onRefresh={this.refreshDataFromServer}
              authorizationHeader={this.state.authorizationHeader}
            />
            <NewCounterForm
              groups={this.state.groups}
              onRefresh={this.refreshDataFromServer}
              authorizationHeader={this.state.authorizationHeader}
            />
            <NewGroupForm
              groups={this.state.groups}
              onRefresh={this.refreshDataFromServer}
              authorizationHeader={this.state.authorizationHeader}
            />
          </div>
        </div>
      );
    } else
      return (
        <Profile
          setToken={this.setToken}
          obtained={this.state.authorizationHeader ? true : false}
        />
      );
  }
}

export default MainPage;
