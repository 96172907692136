export function formatDate(date) {
  var addLeadingZero = (numb) => {
    if (numb / 10 < 1) return "0" + numb;
    else return numb;
  };
  var strDate = "Y-m-d"
    .replace("Y", date.getFullYear())
    .replace("m", addLeadingZero(date.getMonth() + 1))
    .replace("d", addLeadingZero(date.getDate()));
  return strDate;
}

export function addDaysToDate(date, days) {
  let tmp = date;
  tmp.setDate(date.getDate() + days);
  return tmp;
}
